::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #333;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #444;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #222;
}

@media (min-width: 768px) {
html {
  font-size: 16px;
}
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
  background-color: black;
  font-family: 'Quattrocento', serif;
  font-weight: light;
  color: lightgray;
  letter-spacing: 0.15rem;
}

.jumbotron {
  color: rgb(197, 196, 196);
  height: 80vh;
  background-image: radial-gradient(rgba(0, 0, 0, 0.25), black), url('../img/header.JPG');
  background-position: top;
  background-size: cover;
  text-shadow: 2px 2px 5px black;
}

.professionalExp {
  background-image: linear-gradient(black, rgba(224, 221, 221, 0.100)), url('../img/redrock.JPG');
  background-position: center;
  background-size: cover;
}

.title-text {
  letter-spacing: 0.75rem;
  text-transform: uppercase !important;
}

.logo {
  height: 75px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 190px;
  background: radial-gradient(rgba(224, 221, 221, 0.5), rgba(224, 221, 221, 0.001));
}
